import { useState } from 'react';

import logo from './assets/logo.png';
import './styles/App.css';
import '@rainbow-me/rainbowkit/styles.css';

import toast, { Toaster } from 'react-hot-toast';

import { ConnectButton, darkTheme } from '@rainbow-me/rainbowkit';
import { useAccount, useNetwork, useSigner } from 'wagmi';
import { ethers } from "ethers";

import { Container, Row, Col, Form, InputGroup, Button, Alert } from "react-bootstrap";

import abi from './contracts/ABI.json';

window.Buffer = window.Buffer || require('buffer').Buffer;

const contractAddress = '0xD121FfcEc225a1aD94Ec6c6013791224890B2440';

const HoloClaim = () => {

  const { address, isConnected } = useAccount()
  const { chain } = useNetwork();
  const { data: signer, isError, isLoading } = useSigner()

  const [lotpakId, setLotpakId] = useState('');
  const [step, setStep] = useState(1);

  const ownerCheck = async (lotpakId) => {

    if (lotpakId <= 0 || lotpakId >= 556 || lotpakId == null) { toast.error('ID invalide !'); return; }

    if (!address) return;
    try {

      const nftContract = new ethers.Contract(contractAddress, abi, signer);
      const tokenOwner = await nftContract.ownerOf(lotpakId);
      
      if (tokenOwner == address) { toast.success('Propriété confirmée'); setStep(2); } else { toast.error('Ce Lotpak n\'est pas sur le wallet actuellement connecté'); setStep(1); }

    } catch (err) { console.log(err); }
  };

  const holoCheck = async (lotpakId) => {

    if (lotpakId <= 0 || lotpakId >= 556 || lotpakId == null) { toast.error('ID invalide !'); return; }

    if (!address) return;
    try {

      const nftContract = new ethers.Contract(contractAddress, abi, signer);
      const claimedStatus = await nftContract.tokenHoloClaimed(lotpakId);
      
      if (claimedStatus == false) { toast.success('Claim possible'); setStep(3); } else { toast.error('Ce Lotpak a déjà eu sa version holographique'); }

    } catch (err) { console.log(err); }
  };

  const holoClaim = async (lotpakId) => {

    if (lotpakId <= 0 || lotpakId >= 556 || lotpakId == null) { toast.error('ID invalide !'); return; }

    if (!signer) return;
    try {
            const nftContract = new ethers.Contract(contractAddress, abi, signer);
            let nftTxn;
  
            const toast_loading = toast.loading('Claim demandé');
  
            nftTxn = await nftContract.claimHolo(lotpakId);
  
            toast.loading('Claim en cours', {id: toast_loading});
            await nftTxn.wait();
  
            toast.dismiss(toast_loading);
            toast.success('Claim réussi');
            setStep(4);
  
    } catch (err) {
        toast.dismiss();
        toast.error('Claim annulé');

        if (err.code === 'INSUFFICIENT_FUNDS') { toast.error('Erreur : Fonds insuffisants'); }
    }
  }

  const checkForm = () => {

    const handleLotpakIdChange = (e) => { setLotpakId(e.target.value); setStep(1); }

    return(<>

    {step != 4 ? (
    <Row>
        <Col lg={{ span: 6, offset: 3}}>
            <InputGroup className="mb-3">
                <InputGroup.Text className="text-white bg-dark">ID du Lotpak 👽 concerné</InputGroup.Text>
                <Form.Control type="number" min="1" max="555" step="1" placeholder="ID du Lotpak" value={lotpakId} onChange={handleLotpakIdChange} className="text-white bg-dark" />
            </InputGroup>
        </Col>
    </Row>
    ) : null }

    {step == 1 ? (<><Button className="btn-primary" onClick={() => {ownerCheck(lotpakId)}}>1/3. Vérification propriétaire</Button></>) : null }
    {step == 2 ? (<><Button className="btn-primary" onClick={() => {holoCheck(lotpakId)}}>2/3. Vérification version holographique</Button></>) : null }
    {step == 3 ? (<><Button className="btn-success" onClick={() => {holoClaim(lotpakId).then()}}>3/3. Claim de la version holographique</Button></>) : null }
    {step == 4 ? (<> <Alert variant="success">Félicitations, vous avez claim avec succès la version holographique de votre Lotpak #{lotpakId} ! Merci d'ouvrir un ticket sur notre <a href="https://discord.com/invite/galaxygamers" target="_blank">Discord</a> pour poursuivre.</Alert></>) : null }

        </>)
  }

  return (<>

  <Container>

    <Row>
        <Col lg={12}>
      <img src={logo} />
      <div className="connect"><ConnectButton theme={darkTheme()} /></div>

      <h1 className="mb-4">Lotpak - Claim version Holographique</h1>

    {isConnected && chain.id == 137 ? (checkForm()) : null}

    </Col>
    </Row>

        <Row>
            <Col lg={{ span: 6, offset: 3}}>
                <div id="footer" className="my-4 p-2">
                    <span className="sc">Smart Contract : <a href={`https:///polygonscan.com/address/${contractAddress}`} target="_blank">{contractAddress}</a></span><br />
                    <span className='made-with-love'>DApp made with <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg> by <a href="https://profile.rpgmax.fr" target="_blank">RpGmAx</a></span>
                </div>
            </Col>
        </Row>
    </Container>



  </>);
}

export default HoloClaim;