import { useEffect, useState } from 'react';

import logo from './assets/logo.png';
import './styles/App.css';
import '@rainbow-me/rainbowkit/styles.css';

import { Toaster } from 'react-hot-toast';

import HoloClaim from "./HoloClaim.js";

import { getDefaultWallets, RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig, useAccount } from 'wagmi';

import { polygon } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { ConnectButton } from '@rainbow-me/rainbowkit';

const { chains, provider } = configureChains( [polygon],  [ publicProvider() ] );
const { connectors } = getDefaultWallets({ appName: 'Lotpak Checker', chains });
const wagmiClient = createClient({ autoConnect: true, connectors, provider })

function App() {

  const { address, isConnected } = useAccount()  

  return (<WagmiConfig client={wagmiClient}>
  <RainbowKitProvider chains={chains} coolMode>
  <HoloClaim />
  <Toaster position="top-center" toastOptions={{style: { border: '1px solid #fff', padding: '18px', color: '#000', }, iconTheme: { primary: '#000', secondary: '#FFF', }}} reverseOrder={false}/>
  </RainbowKitProvider>
  </WagmiConfig>);
}

export default App;